const Footer = () => {
    return(
        <footer className="footer">
            <div className="footer_wrapper">
                <div className="footer_wrapper_container">
                    <div className="footer_wrapper_container_cont">
                        <span className="footer_wrapper_container_cont_name">
                             m<span>o</span><span>o</span>d
                        </span>
                      <ul className="footer_wrapper_container_cont_services_ul">
                        <li className="footer_wrapper_container_cont_services_ul_li">HAIR</li>
                        <li className="footer_wrapper_container_cont_services_ul_li">NAILS</li>
                        <li className="footer_wrapper_container_cont_services_ul_li">MAKEUP</li>
                        <li className="footer_wrapper_container_cont_services_ul_li">SKIN</li>
                        <li className="footer_wrapper_container_cont_services_ul_li">BARBER</li>
                      </ul>
                      <p className="footer_wrapper_container_cont_p">
                        We offer a wide range of hair services that cater to the unique needs and
                        preferences of every client.
                      </p>
                    </div>

                    <div className="footer_wrapper_container_cont">
                        <div className="footer_wrapper_container_cont_address_icon">
                        <svg id="icon-location" viewBox="0 0 20 20">
                            <path d="M10 20s-7-9.13-7-13c0-3.866 3.134-7 7-7s7 3.134 7 7v0c0 3.87-7 13-7 13zM10 9c1.105 0 2-0.895 2-2s-0.895-2-2-2v0c-1.105 0-2 0.895-2 2s0.895 2 2 2v0z"></path>
                        </svg>
                        </div>
                        <h6 className="footer_wrapper_container_cont_h6">ADDRESS</h6>
                        <ul className="footer_wrapper_container_cont_address_ul">
                            <li className="footer_wrapper_container_cont_address_ul_li">14 <sup>th</sup></li>
                            <li className="footer_wrapper_container_cont_address_ul_li">GTC Building</li>
                            <li className="footer_wrapper_container_cont_address_ul_li">Westlands Nairobi</li>
                        </ul>
                    </div>
                    <div className="footer_wrapper_container_cont">
                        <div className="footer_wrapper_container_cont_address_icon">
                        <svg id="icon-location" viewBox="0 0 20 20">
                            <path d="M10 20s-7-9.13-7-13c0-3.866 3.134-7 7-7s7 3.134 7 7v0c0 3.87-7 13-7 13zM10 9c1.105 0 2-0.895 2-2s-0.895-2-2-2v0c-1.105 0-2 0.895-2 2s0.895 2 2 2v0z"></path>
                        </svg>
                        </div>
                        <h6 className="footer_wrapper_container_cont_h6">ADDRESS</h6>
                        <ul className="footer_wrapper_container_cont_address_ul">
                            <li className="footer_wrapper_container_cont_address_ul_li">14 <sup>th</sup></li>
                            <li className="footer_wrapper_container_cont_address_ul_li">GTC Building</li>
                            <li className="footer_wrapper_container_cont_address_ul_li">Westlands Nairobi</li>
                        </ul>
                    </div>
                    <div className="footer_wrapper_container_cont">
                        <div className="footer_wrapper_container_cont_address_icon">
                        <svg id="icon-location" viewBox="0 0 20 20">
                            <path d="M10 20s-7-9.13-7-13c0-3.866 3.134-7 7-7s7 3.134 7 7v0c0 3.87-7 13-7 13zM10 9c1.105 0 2-0.895 2-2s-0.895-2-2-2v0c-1.105 0-2 0.895-2 2s0.895 2 2 2v0z"></path>
                        </svg>
                        </div>
                        <h6 className="footer_wrapper_container_cont_h6">ADDRESS</h6>
                        <ul className="footer_wrapper_container_cont_address_ul">
                            <li className="footer_wrapper_container_cont_address_ul_li">14 <sup>th</sup></li>
                            <li className="footer_wrapper_container_cont_address_ul_li">GTC Building</li>
                            <li className="footer_wrapper_container_cont_address_ul_li">Westlands Nairobi</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </footer>
    )
}
export default Footer;